<template>
  <div>
<!--    <div class="breadCurmb mt-3">-->
<!--      <label>-->
<!--        <router-link to="/">{{ $t("Home") }}</router-link>-->
<!--      </label>-->
<!--      <span>/</span>-->
<!--      <label active>{{ $t("WorkGroup") }}</label>-->
<!--      <span>/</span>-->
<!--      <label style="cursor:pointer" @click="goBack">-->
<!--        <span>-->
<!--          {{ $t('العناوين') }}-->
<!--        </span>-->

<!--      </label>-->
<!--      <span>/</span>-->
<!--      <label>{{  $t("Add") }}</label>-->
<!--    </div>-->

<!--    <v-toolbar-title class="mt-3">{{ $t("العناوين") }}</v-toolbar-title>-->

    <v-tabs class="mt-2" v-model="activeTab" background-color="green" dark>
      <v-tab>{{ $t("بيانات المنزل") }}</v-tab>
      <v-tab>{{ $t("  بيانات المكتب") }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.City`),
                  }"
                >
                  <label>{{ $t("City") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="City"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('City')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.city_id"
                      item-value="id"
                      item-text="name"
                      :items="cityList"
                      @change="getNeighborhoodList(formData.home.city_id,'home')"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.City`)"
                >
                  {{ errors.first(`addEditValidation.City`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.Neighborhood`),
                  }"
                >
                  <label>{{ $t("Neighborhood") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="Neighborhood"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Neighborhood')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.branch_id"
                      item-value="id"
                      item-text="name"
                      :items="neighborhoodHomeList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.Neighborhood`)"
                >
                  {{ errors.first(`addEditValidation.Neighborhood`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.company`),
                  }"
                >
                  <label>{{ $t("Company") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="company"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Company')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.company_id"
                      item-value="id"
                      item-text="name"
                      :items="companyList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.company`)"
                >
                  {{ errors.first(`addEditValidation.company`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.Period`),
                  }"
                >
                  <label>{{ $t("Period") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="Period"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Period')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.period"
                      item-value="id"
                      item-text="name"
                      :items="periodList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.Period`)"
                >
                  {{ errors.first(`addEditValidation.Period`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.HomeNumber`),
                  }"
                >
                  <label>{{ $t("HomeNumber") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="HomeNumber"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      :data-vv-as="$t('HomeNumber')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.home_number"
                  ></v-text-field>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.HomeNumber`)"
                >
                  {{ errors.first(`addEditValidation.HomeNumber`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.address`),
                  }"
                >
                  <label>{{ $t("Address") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="address"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      :data-vv-as="$t('Address')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.address"
                  ></v-text-field>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.address`)"
                >
                  {{ errors.first(`addEditValidation.address`) }}
                </div>
              </v-col>


              <v-col class="py-0"  :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.link_location`),
                  }"
                >
                  <label> {{ $t("عنوان المستخدم") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="link_location"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('link_location')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.link_location"
                  ></v-text-field>
                </div>

                <div class="help-block"  v-if="errors.has(`addEditValidation.link_location`)">
                  {{ errors.first(`addEditValidation.link_location`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addEditValidation.notes2`),
                  }"
                >
                  <label>{{ $t("Notes") }}</label>
                  <v-textarea
                      class="d-block my-2"
                      type="text"
                      name="notes2"
                      rows="4"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      :data-vv-as="$t('Notes')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.home.notes"
                  ></v-textarea>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.notes2`)"
                >
                  {{ errors.first(`addEditValidation.notes2`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row class="float-left">
              <v-btn     color="success" dark  outlined  @click="saveHome">
                {{ $t("حفظ بيانات المنزل") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>

      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.City`),
                  }"
                >
                  <label>{{ $t("City") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="City"
                      data-vv-scope="addOfficeValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('City')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.city_id"
                      item-value="id"
                      item-text="name"
                      :items="cityList"
                      @change="getNeighborhoodList(formData.office.city_id,'office')"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addOfficeValidation.City`)"
                >
                  {{ errors.first(`addOfficeValidation.City`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.Neighborhood`),
                  }"
                >
                  <label>{{ $t("Neighborhood") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="Neighborhood"
                      data-vv-scope="addOfficeValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Neighborhood')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.branch_id"
                      item-value="id"
                      item-text="name"
                      :items="neighborhoodOfficeList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addOfficeValidation.Neighborhood`)"
                >
                  {{ errors.first(`addOfficeValidation.Neighborhood`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.company`),
                  }"
                >
                  <label>{{ $t("Company") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="company"
                      data-vv-scope="addOfficeValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Company')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.company_id"
                      item-value="id"
                      item-text="name"
                      :items="companyList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addOfficeValidation.company`)"
                >
                  {{ errors.first(`addOfficeValidation.company`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="3">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.Period`),
                  }"
                >
                  <label>{{ $t("Period") }}</label>
                  <v-autocomplete
                      class="d-block my-2"
                      name="Period"
                      data-vv-scope="addOfficeValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Period')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.period"
                      item-value="id"
                      item-text="name"
                      :items="periodList"
                      clearable
                  ></v-autocomplete>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addOfficeValidation.Period`)"
                >
                  {{ errors.first(`addOfficeValidation.Period`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.HomeNumber`),
                  }"
                >
                  <label>{{ $t("HomeNumber") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="HomeNumber"
                      data-vv-scope="addOfficeValidation"
                      v-validate="''"
                      :data-vv-as="$t('HomeNumber')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.home_number"
                  ></v-text-field>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addOfficeValidation.HomeNumber`)"
                >
                  {{ errors.first(`addOfficeValidation.HomeNumber`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.address`),
                  }"
                >
                  <label>{{ $t("Address") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="address"
                      data-vv-scope="addOfficeValidation"
                      v-validate="''"
                      :data-vv-as="$t('Address')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.address"
                  ></v-text-field>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addOfficeValidation.address`)"
                >
                  {{ errors.first(`addOfficeValidation.address`) }}
                </div>
              </v-col>


              <v-col class="py-0"  :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.link_location`),
                  }"
                >
                  <label> {{ $t("عنوان المستخدم") }}</label>
                  <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="link_location"
                      data-vv-scope="addOfficeValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('link_location')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.link_location"
                  ></v-text-field>
                </div>

                <div class="help-block"  v-if="errors.has(`addOfficeValidation.link_location`)">
                  {{ errors.first(`addOfficeValidation.link_location`) }}
                </div>
              </v-col>


              <v-col class="py-0" :cols="6">
                <div
                    :class="{
                    'has-error': errors.has(`addOfficeValidation.notes2`),
                  }"
                >
                  <label>{{ $t("Notes") }}</label>
                  <v-textarea
                      class="d-block my-2"
                      type="text"
                      name="notes2"
                      rows="4"
                      data-vv-scope="addOfficeValidation"
                      v-validate="''"
                      :data-vv-as="$t('Notes')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.office.notes"
                  ></v-textarea>
                </div>

                <div
                    class="help-block"
                    v-if="errors.has(`addOfficeValidation.notes2`)"
                >
                  {{ errors.first(`addOfficeValidation.notes2`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row class="float-left">
              <v-btn     color="success" dark  outlined  @click="saveOffice">
                {{ $t("حفظ بيانات المكتب") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>

    </v-tabs-items>


    <BlockUI v-if ="loadClicked" :message="msg">
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
    </BlockUI>


  </div>
</template>

<script>
export default {
  name: "createAddress",
  data() {
    return {
      user: localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : [],
      defaultDays: [],
      loadClicked: false,
      msg: 'برجاء الانتظار لحين اكتمال العملية',
      activeTab: 0,
      myId : null,
      formData: {
        home:{
          city_id: null,
          branch_id: null,
          period: null,
          home_number: null,
          company_id: null,
          address: null,
          notes: null,
          link_location:null,
        },
        office :{
          city_id: null,
          branch_id: null,
          period: null,
          home_number: null,
          company_id: null,
          address: null,
          notes: null,
          link_location:null,
        }
      },

      cityList: [],
      neighborhoodHomeList: [],
      neighborhoodOfficeList: [],
      companyList: [],
      periodList: [
        {
          id: 1,
          name: "صباحي",
        },
        {
          id: 2,
          name: "مسائي",
        },
      ],
    };
  },
  created() {

    const params = this.$route.params;
    if ('customer_id' in params) {
      this.myId = params.customer_id;
    } else {
      this.myId = this.$route.params.id;
    }

    this.$store.dispatch(`subscribe/cityList`).then((res) => {
      this.cityList = res.data;
    });

    this.$store.dispatch(`subscribe/companyList`).then((res) => {
      this.companyList = res.data;
    });

    const loading = this.$loading({
      lock: true, text: "Loading", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)",
    });
    this.$store.dispatch(`address/listData`, this.myId).then((res) => {

      if (res.data.home){
        // this.formData.home.branch_id = null;
        this.formData.home = res.data.home;
        this.getNeighborhoodList(res.data.home.city_id,'home');
      }

      if (res.data.office){
        // this.formData.office.branch_id = null;
        this.formData.office = res.data.office;
        this.getNeighborhoodList(res.data.office.city_id,'office');
      }

      loading.close();
    });


  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    next() {
      this.activeTab += 1;
    },
    getNeighborhoodList(id,type) {
      if (type === 'home'){
        this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
          this.neighborhoodHomeList = res.data;
        });
      }
      if (type === 'office'){
        this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
          this.neighborhoodOfficeList = res.data;
        });
      }

    },
    saveHome() {
      this.$validator.validateAll("addEditValidation").then((valid) => {

        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        let sendData = JSON.parse(JSON.stringify(this.formData.home));

        sendData.id = this.myId;
        sendData.type = "home";
        if (sendData.company_id === 0) {
          sendData.company_id = null;
        }

        this.$store.dispatch(`address/updateOrCreateData`, sendData).then((res) => {
          this.notifySuccess("Save", res);
          // this.$router.push({ name: "customers" });
        }).catch((error) => {

          let errorsArray = [];

          // Check if the error object has response and data properties
          if (error.response && error.response.data) {
            const responseData = error.response.data;

            // Check if the response data has an 'errors' array
            if (Array.isArray(responseData.errors)) {
              // If 'errors' is an array, add all error messages to the errorsArray
              errorsArray = responseData.errors;
            } else if (typeof responseData.errors === 'object') {
              // If 'errors' is an object, iterate over its values and add them to errorsArray
              errorsArray = Object.values(responseData.errors);
            } else if (responseData.message) {
              // If there's a single error message, add it to the errorsArray
              errorsArray.push(responseData.message);
            }
          } else {
            // If the error object doesn't have expected properties, log the error
            console.error('Unexpected error:', error);
            errorsArray.push('An unexpected error occurred.');
          }

          this.errorsApi = errorsArray;
        })

      });

    },
    saveOffice() {
      this.$validator.validateAll("addOfficeValidation").then((valid) => {

        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        let sendData = JSON.parse(JSON.stringify(this.formData.office));

        sendData.id = this.myId;
        sendData.type = "office";
        if (sendData.company_id === 0) {
          sendData.company_id = null;
        }

        this.$store.dispatch(`address/updateOrCreateData`, sendData).then((res) => {
          this.notifySuccess("Save", res);
          // this.$router.push({ name: "customers" });
        }).catch((error) => {

          let errorsArray = [];

          // Check if the error object has response and data properties
          if (error.response && error.response.data) {
            const responseData = error.response.data;

            // Check if the response data has an 'errors' array
            if (Array.isArray(responseData.errors)) {
              // If 'errors' is an array, add all error messages to the errorsArray
              errorsArray = responseData.errors;
            } else if (typeof responseData.errors === 'object') {
              // If 'errors' is an object, iterate over its values and add them to errorsArray
              errorsArray = Object.values(responseData.errors);
            } else if (responseData.message) {
              // If there's a single error message, add it to the errorsArray
              errorsArray.push(responseData.message);
            }
          } else {
            // If the error object doesn't have expected properties, log the error
            console.error('Unexpected error:', error);
            errorsArray.push('An unexpected error occurred.');
          }

          this.errorsApi = errorsArray;
        })

      });

    },
  },
};
</script>

